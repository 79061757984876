import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';

// Connects to data-controller="select"
export default class extends Controller {
  static targets = ['select', 'form', 'textInput', 'submitButton'];
  static values = { url: String, redirect: Boolean };

  connect() {
    super.connect();
    this.initTomSelect();
  }

  disconnect() {
    if (this.select) {
      this.select.destroy();
    }
  }

  initTomSelect() {
    if (this.hasSelectTarget) {
      let url = `${this.urlValue}`;

      this.select = new TomSelect(this.selectTarget, {
        plugins: [
          'input_autogrow',
          'remove_button',
          'restore_on_backspace',
        ],
        valueField: 'id',
        labelField: 'displayName',
        searchField: ['displayName', 'description'],
        maxItems: 1,
        selectOnTab: true,
        placeholder: 'Type and select to initiate a command',
        closeAfterSelect: true,
        hidePlaceholder: false,
        preload: false,
        create: false,
        openOnFocus: false,
        highlight: true,
        maxOptions: 10,
        allowEmptyOption: true,
        persist: true,
        sortField: {
          field: 'name',
          direction: 'asc',
        },
        onDelete: (values) => {
          console.log('Deleted:', values);
          this.select.clearOptions();
          this.select.settings.load = this.loadOptions.bind(this);
          this.select.refreshOptions(true);
        },
        onItemAdd: (value, item) => {
          // After an item is selected, focus on the input
          this.select.control_input.focus();
          // Clear the search to allow for additional typing
          this.select.setTextboxValue('');

          // Disable the ability to search once an item is selected
          this.select.settings.load = null;
          this.select.close(); // Close dropdown once the option is selected
          this.select.clearOptions();
        },
        onBlur: () => {
          this.textInputTarget.value = this.select.lastQuery;
        },
        onType: (str) => {
          console.log('Typed text:', str);
          this.textInputTarget.value = str;
          if (this.select.getValue().length === 0) {
            this.select.load(str);
          }
        },
        onChange: (value) => {
          console.log(value);
        },
        load: this.loadOptions.bind(this),
        render: {
          option: function (data, escape) {
            console.log('Data:', data);
            return `<div class="flex items-center gap-2 hover:bg-gray-200">
                <img class="w-5 h-6" src="${escape(data.logo)}" alt="${escape(data.display_name)} logo" />
                <div>
                  <span class="block text-black">
                    ${escape(data.display_name)}
                  </span>
                  <span class="text-black">
                    ${escape(data.description)}
                  </span>
                </div>
              </div>`
          },
          item: function (data, escape) {
            return `<div class="text-black flex items-center h-full">
                <span>${escape(data.display_name)}</span>
              </div>
              `;
          },
          no_results: null,
        },
      });

      this.formTarget.addEventListener('submit', (event) => {
        this.submitButtonTarget.disabled = true
        if (event.customSubmit) {
          return;
        }

        event.preventDefault();
        const selectedOption = this.select.getValue();
        const additionalText = this.select.control_input.value;

        if (additionalText && additionalText !== '') {
          this.textInputTarget.value = additionalText;
        }

        if (this.redirectValue) {
          this.formTarget.submit();
        } else {
          const customSubmitEvent = new Event('submit', {
            bubbles: true,
            cancelable: true,
          });
          customSubmitEvent.customSubmit = true;

          this.formTarget.dispatchEvent(customSubmitEvent);
        }
      });
    }
  }
  loadOptions(search, callback) {
    const url = `${this.urlValue}`;
    if (this.select.getValue().length === 0) {
      fetch(`${url}?query=${search}`)
        .then((response) => response.json())
        .then((json) => {
          console.log('Load:', json);
          callback(json);
        })
        .catch(() => {
          callback();
        });
    } else {
      callback();
    }
  }
}
